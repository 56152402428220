import React, { useEffect, useRef, useState } from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Header from '@amzn/awsui-components-react/polaris/header';
import Table from '@amzn/awsui-components-react/polaris/table';
import Box from "@amzn/awsui-components-react/polaris/box";
import { useHistory } from 'react-router-dom';
import Button from '@amzn/awsui-components-react/polaris/button';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import { COLUMN_DEFINITIONS } from './table-config';
import { ServiceMissingAlarm } from '../../api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import ApiFactory from '../../api/ApiFactory';

export default function serviceMissingAlarmTable() {
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<object>();
  const [fetchError, setFetchError] = useState<string | null>(null);
  const history = useHistory();
  const Api = ApiFactory();
  const loadItems = async () => {
    setLoading(true);
    setFetchError(null);
    try {
	setResponse( (await Api.getServiceMissingAlarms()).data);
    } catch (error: unknown) {
      setFetchError(error instanceof Error ? error.message : String(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadItems();
  }, []);
  // a dictionary that maps service name with missing alarm info
  var serviceMissingAlarmObj:  {[key:string]:ServiceMissingAlarm[]} = {"":[]}; 
  // sample response {"service_name": { "missing_alarm_info" : [list of stacks]}}
  if (response != undefined){
  //process data received from the backend lambda and group info by service name
    Object.keys(response).forEach(serviceName => {
        Object.keys(response[serviceName]).forEach(resourceAlarmType => {
	   //get missing alarm info from nested dictionary
           var missingAlarmInfo : string[] = resourceAlarmType.split("&");
           var resourceType = missingAlarmInfo[0];
           var missingAlarmType = missingAlarmInfo[1];
           var accountList: string[] = response[serviceName][resourceAlarmType];
	   //length of list = number of such alarm missing
           var numberOfAlarmMissing = response[serviceName][resourceAlarmType].length;
           let obj : ServiceMissingAlarm = {
                resourceType: resourceType,
                missingAlarmType: missingAlarmType,
                accountList: accountList,
                numberOfAlarmMissing: numberOfAlarmMissing,
        }
      if (serviceMissingAlarmObj.hasOwnProperty(serviceName) == false){
	serviceMissingAlarmObj[serviceName] = [obj];
      } 
      else{
	serviceMissingAlarmObj[serviceName].push(obj);
      }
   });
    // delete empty header after processing data: empty header set to display header when loading data
      delete serviceMissingAlarmObj[""];
  });
}
   function pushServiceName(serviceName:string){
   // push service name to onboarding page if customers want to onboard to the same service
	history.push({
	  pathname: "/onboardingPage",
	  state: {data:serviceName}
	})
}
    //customizes visible columns in the table
    const visible: string[]  =  ["resourceType","missingAlarmType","numberOfAlarmMissing","accountList"];

  return (
   <Container>
      <SpaceBetween size="l">
	{Object.keys(serviceMissingAlarmObj).map((key)=> {
        //return empty state table when response is empty
	if (key=="" || Object.keys(serviceMissingAlarmObj).length == 0){
	return(<Table
	      loading={loading}
      	      loadingText="Loading instances"
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No resources</b>
                  <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                  >
                    No resources to display.
                  </Box>
                  <Button href="#/onboardingPage" variant="primary">Onboard your stacks</Button>
                </Box>
              }
      	      columnDefinitions={COLUMN_DEFINITIONS}
	      items={[]}
	      visibleColumns={visible}
         />
	) 
	}
	else{
	return(
	    [<SpaceBetween direction="vertical" size="xl">
	     <Header variant='h1' key={key}> {key} </Header>
	     <Button loading={loading} onClick={() => pushServiceName(key)}> Onboard Accounts to {key} </Button>
	    </SpaceBetween>,
	     <Table
	      loading={loading}
      	      loadingText="Loading instances"
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No resources</b>
                  <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                  >
                    No resources to display.
                  </Box>
                  <Button href="#/onboardingPage" variant="primary">Onboard your stacks</Button>
                </Box>
              }
      	      columnDefinitions={COLUMN_DEFINITIONS}
      	      // sort the items in the table first based on resource type name then based on number of alarm missing in descending order
	      items={serviceMissingAlarmObj[key].sort((a,b) => (a.resourceType > b.resourceType ? -1 : 1 && a.numberOfAlarmMissing > b.numberOfAlarmMissing ? -1 : 1))}
	      visibleColumns={visible}
         />])}
	})}	
     </SpaceBetween>
  </Container>
  )
}
