import { Route } from 'react-router-dom';
import ServiceHomepage from './home';
import onboardingForm from './onboardingPage';
import displayStackForm from './displayStacks';
import stackTable from './stackTable'
import accountTable from './accountTable'
import serviceTable from './serviceTable'
export default function App() {
  return (
    <div>
      <Route exact path="/" component={ServiceHomepage} />
      <Route path="/onboardingPage" component={onboardingForm} />
      <Route path="/displayStacks" component={displayStackForm} />
      <Route path="/stackTable" component={stackTable} />
      <Route path="/accountTable" component={accountTable} />
      <Route path="/serviceTable" component={serviceTable} />
    </div>
  );
}
