import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import BreadcrumbGroup, { BreadcrumbGroupProps } from '@amzn/awsui-components-react/polaris/breadcrumb-group';

const HOME = { text: 'AWSVision', href: '#/' };
const items: SideNavigationProps.Item[] = [
  {
    type: 'link', 
    text: 'Onboarding Page', 
    href: '#/onboardingPage'
  },
  { type: "divider" },
  {
    type: 'section',
    text: 'Missing Alarms',
    items: [
      { type: 'link', text: 'Service Missing Alarm Page', href: '#/serviceTable' },
      { type: 'link', text: 'Account Missing Alarm Page', href: '#/accountTable' },
      { type: 'link', text: 'Stack Missing Alarm Page', href: '#/stackTable' },
    ]
  }
];

export function Breadcrumbs({ items }: { items: BreadcrumbGroupProps.Item[] }) {
  return <BreadcrumbGroup items={[HOME, ...items]} />;
}

// Class ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
class ServiceNavigationLocal extends Component<RouteComponentProps> {
  // If the provided link is empty, do not redirect pages
  onFollowHandler(event: CustomEvent<SideNavigationProps.FollowDetail>) {
    event.preventDefault();
    if (event.detail.href) {
      this.props.history.push(event.detail.href.substring(1));
    }
  }

  render() {
    return (
      <SideNavigation
        header={HOME}
        items={items}
        activeHref={`#${this.props.location.pathname}`}
        onFollow={this.onFollowHandler.bind(this)}
      />
    );
  }
}

export const ServiceNavigation = withRouter(ServiceNavigationLocal);
