/* tslint:disable */
/* eslint-disable */
/**
 * Vision API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'accountName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Account
     */
    'stackList': Array<string>;
}
/**
 * 
 * @export
 * @interface AccountDict
 */
export interface AccountDict {
    /**
     * 
     * @type {string}
     * @memberof AccountDict
     */
    'serviceName': string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AccountDict
     */
    'accountStackMap': { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface AccountMissingAlarm
 */
export interface AccountMissingAlarm {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountMissingAlarm
     */
    'stackList': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AccountMissingAlarm
     */
    'resourceType': string;
    /**
     * 
     * @type {string}
     * @memberof AccountMissingAlarm
     */
    'missingAlarmType': string;
    /**
     * 
     * @type {number}
     * @memberof AccountMissingAlarm
     */
    'numberOfAlarmMissing': number;
}
/**
 * 
 * @export
 * @interface MissingAlarm
 */
export interface MissingAlarm {
    /**
     * 
     * @type {string}
     * @memberof MissingAlarm
     */
    'resourceName': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MissingAlarm
     */
    'alarms': { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ServiceMissingAlarm
 */
export interface ServiceMissingAlarm {
    /**
     * 
     * @type {string}
     * @memberof ServiceMissingAlarm
     */
    'resourceType': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceMissingAlarm
     */
    'missingAlarmType': string;
    /**
     * 
     * @type {number}
     * @memberof ServiceMissingAlarm
     */
    'numberOfAlarmMissing': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceMissingAlarm
     */
    'accountList': Array<string>;
}
/**
 * 
 * @export
 * @interface StackMissingAlarm
 */
export interface StackMissingAlarm {
    /**
     * 
     * @type {string}
     * @memberof StackMissingAlarm
     */
    'stackName': string;
    /**
     * 
     * @type {string}
     * @memberof StackMissingAlarm
     */
    'stackId': string;
    /**
     * 
     * @type {string}
     * @memberof StackMissingAlarm
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof StackMissingAlarm
     */
    'resourceName': string;
    /**
     * 
     * @type {string}
     * @memberof StackMissingAlarm
     */
    'resourceType': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StackMissingAlarm
     */
    'missingAlarms': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StackMissingAlarm
     */
    'serviceName': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get stacks for a onboarding account
         * @param {Array<string>} iamRoleArn iamRoleArn of onboarding accounts
         * @param {string} serviceName service name that onboarding accounts belong to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayStacks: async (iamRoleArn: Array<string>, serviceName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iamRoleArn' is not null or undefined
            assertParamExists('displayStacks', 'iamRoleArn', iamRoleArn)
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('displayStacks', 'serviceName', serviceName)
            const localVarPath = `/stacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (iamRoleArn) {
                localVarQueryParameter['iamRoleArn'] = iamRoleArn;
            }

            if (serviceName !== undefined) {
                localVarQueryParameter['serviceName'] = serviceName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get missing alarms on account level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountMissingAlarms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accountMissingAlarms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get missing alarms on service level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceMissingAlarms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/serviceMissingAlarms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get missing alarms on stack level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStackMissingAlarms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stackMissingAlarms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post stacks to ddb
         * @param {AccountDict} accountDict post info to ddb2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStacks: async (accountDict: AccountDict, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountDict' is not null or undefined
            assertParamExists('postStacks', 'accountDict', accountDict)
            const localVarPath = `/stacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountDict, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get stacks for a onboarding account
         * @param {Array<string>} iamRoleArn iamRoleArn of onboarding accounts
         * @param {string} serviceName service name that onboarding accounts belong to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async displayStacks(iamRoleArn: Array<string>, serviceName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: boolean; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.displayStacks(iamRoleArn, serviceName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get missing alarms on account level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountMissingAlarms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Array<string>; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountMissingAlarms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get missing alarms on service level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceMissingAlarms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: Array<string>; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceMissingAlarms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get missing alarms on stack level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStackMissingAlarms(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<StackMissingAlarm>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStackMissingAlarms(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post stacks to ddb
         * @param {AccountDict} accountDict post info to ddb2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStacks(accountDict: AccountDict, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStacks(accountDict, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Get stacks for a onboarding account
         * @param {Array<string>} iamRoleArn iamRoleArn of onboarding accounts
         * @param {string} serviceName service name that onboarding accounts belong to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayStacks(iamRoleArn: Array<string>, serviceName: string, options?: any): AxiosPromise<{ [key: string]: { [key: string]: boolean; }; }> {
            return localVarFp.displayStacks(iamRoleArn, serviceName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get missing alarms on account level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountMissingAlarms(options?: any): AxiosPromise<{ [key: string]: { [key: string]: Array<string>; }; }> {
            return localVarFp.getAccountMissingAlarms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get missing alarms on service level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceMissingAlarms(options?: any): AxiosPromise<{ [key: string]: { [key: string]: Array<string>; }; }> {
            return localVarFp.getServiceMissingAlarms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get missing alarms on stack level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStackMissingAlarms(options?: any): AxiosPromise<{ [key: string]: Array<StackMissingAlarm>; }> {
            return localVarFp.getStackMissingAlarms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post stacks to ddb
         * @param {AccountDict} accountDict post info to ddb2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStacks(accountDict: AccountDict, options?: any): AxiosPromise<void> {
            return localVarFp.postStacks(accountDict, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Get stacks for a onboarding account
     * @param {Array<string>} iamRoleArn iamRoleArn of onboarding accounts
     * @param {string} serviceName service name that onboarding accounts belong to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public displayStacks(iamRoleArn: Array<string>, serviceName: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).displayStacks(iamRoleArn, serviceName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get missing alarms on account level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAccountMissingAlarms(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAccountMissingAlarms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get missing alarms on service level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getServiceMissingAlarms(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getServiceMissingAlarms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get missing alarms on stack level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStackMissingAlarms(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStackMissingAlarms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post stacks to ddb
     * @param {AccountDict} accountDict post info to ddb2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postStacks(accountDict: AccountDict, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postStacks(accountDict, options).then((request) => request(this.axios, this.basePath));
    }
}


