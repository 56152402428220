import { useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import HomepageContent from './HomepageContent';
import { ServiceNavigation } from '../navigation';
import { appLayoutNavigationLabels } from '../../common/labels';
import ToolsPanel from './ToolsPanel';
import './styles.scss';

export default function ServiceHomepage() {
  const [navigationOpen, setNavigationOpen] = useState(true);
  const [toolsOpen, setToolsOpen] = useState(true);
  return (
    <AppLayout
      navigation={<ServiceNavigation />}
      content={<HomepageContent />}
      contentType="default"
      disableContentPaddings={true}
      navigationOpen={navigationOpen}
      onNavigationChange={event => setNavigationOpen(event.detail.open)}
      tools={<ToolsPanel />}
      toolsOpen={toolsOpen}
      onToolsChange={event => setToolsOpen(event.detail.open)}
      ariaLabels={appLayoutNavigationLabels}
    />
  );
}
