import { Component,useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import ToolsPanel from './ToolsPanel';
import StackMissingAlarmTable from './stackMissingAlarmTable';
import { appLayoutLabels } from '../../common/labels';


export default function stackTable() {
  const [toolsOpen, setToolsOpen] = useState(false);
    return (
      <AppLayout
        navigation={<ServiceNavigation />}
        breadcrumbs={<Breadcrumbs items={[{ text: 'Service Missing Alarm', href: '#/serviceTable' },{ text: 'Account Missing Alarm', href: '#/accountTable' },{ text: 'Stack Missing Alarm', href: '' }]} />}
        content={<StackMissingAlarmTable />}
        contentType="table"
        tools={<ToolsPanel />}
        toolsOpen={toolsOpen}
        onToolsChange={event => setToolsOpen(event.detail.open)}
        ariaLabels={appLayoutLabels}
      />
    );
}
