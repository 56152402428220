import { Component,useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import ToolsPanel from './ToolsPanel';
import ServiceMissingAlarmTable from './serviceMissingAlarmTable';
import { appLayoutLabels } from '../../common/labels';


export default function serviceTable() {
  const [toolsOpen, setToolsOpen] = useState(false);
    return (
      <AppLayout
        navigation={<ServiceNavigation />}
        breadcrumbs={<Breadcrumbs items={[{ text: 'Service Missing Alarm', href: '' }]} />}
        content={<ServiceMissingAlarmTable />}
        contentType="table"
        tools={<ToolsPanel />}
        toolsOpen={toolsOpen}
        onToolsChange={event => setToolsOpen(event.detail.open)}
        ariaLabels={appLayoutLabels}
      />
    );
}
