import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import { Icon } from '@amzn/awsui-components-react';
export default function ToolsPanel() {
  const permissioncode : string = JSON.stringify({
    "Version": "2012-10-17",
    "Statement": [
        {
            "Effect": "Allow",
            "Action": [
                "cloudformation:Describe*",
                "cloudformation:EstimateTemplateCost",
                "cloudformation:Get*",
                "cloudformation:List*",
                "cloudformation:ValidateTemplate",
                "cloudformation:Detect*",
                "cloudwatch:Describe*",
                "cloudwatch:Get*",
                "cloudwatch:List*"
            ],
            "Resource": "*"
        }
    ]
},null,2);
  const trustPolicy: string = JSON.stringify ({
	"Version": "2012-10-17",
	"Statement": [
		{
			"Effect": "Allow",
			"Principal": {
				"Service": "lambda.amazonaws.com",
				"AWS": [
					"arn:aws:iam::663571280965:root",
					"arn:aws:iam::386966991245:root",
				]
			},
			"Action": "sts:AssumeRole",
			"Condition": {}
		}
	]
},null,2);
  return (
    <HelpPanel
      header={<h2>AWSVision</h2>}
      footer={
      <ul>
        <li>
            <span>
                <a href="/"target="_blank" rel="noopener noreferrer">
                    <Icon name="contact"/>
                    &nbsp;&nbsp;Vision's Wiki
                </a>
            </span>
        </li>
        <li>
            <span>
                <a href="https://issues.amazon.com/issues/create?template=2d244935-648a-47e6-9f5a-b9fd9b1299a1" target="_blank" rel="noopener noreferrer">
                    <Icon name="contact"/>
                    &nbsp;&nbsp;Feature Request
                </a>
            </span>
        </li>
        <li>
            <span>
                <a href="https://issues.amazon.com/issues/create?template=caf78af4-fcb8-4690-b973-f07c406b54f8" target="_blank" rel="noopener noreferrer">
                    <Icon name="bug"/>
                    &nbsp;&nbsp;Report a Bug
                </a>
            </span>
        </li>
               <li>
            <span>
                <a href="https://w.amazon.com/bin/view/AWSCP/MVPDevOps/Projects/Prometheus/OnePager" target="_blank" rel="noopener noreferrer">
                    <Icon name="external"/>
                    &nbsp;&nbsp;FAQs
                </a>
            </span>
        </li>
    </ul>
      }
    >
   <div> 
	<h3>Note for Input Service Name</h3>
        <ul>
	  <li>Please input the service name that the onboarding accounts belong to</li>
          <li>All the onboarding accounts must belong to the same service for this version of Vision</li>
          <li>No special characters are allowed in the service name</li>
        </ul>	
	<h3>Code snippets to create IAM role</h3>
	<h2> Trust Policy </h2>
	<pre> {trustPolicy}
	</pre>
	<h2> permission inline policy </h2>
	<pre> {permissioncode}
	</pre>

 <ul>
          <li> <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-service.html"> Properly setup IAM role ARN in your account</a> </li>
        </ul>
   </div>
   </HelpPanel>
  );
}

