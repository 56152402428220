import { ReactNode } from 'react';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { StackMissingAlarm } from '../../api/generated-src';
import ApiFactory from '../../api/ApiFactory';

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<StackMissingAlarm>[] = [
// definition of table columns
        {
          id: "resourceType",
          header: "resourceType",
          cell: item => item.resourceType || "-",
        },
        {
          id: "resourceName",
          header: "resourceName",
          cell: item => item.resourceName || "-",
        },
        {
          id: "stackName",
          header: "stackName",
          cell: item => item.stackName|| "-",
        },
        {
          id: "accountId",
          header: "accountId",
          cell: item => item.accountId|| "-",
        },
        {
          id: "serviceName",
          header: "serviceName",
          cell: item => item.serviceName|| "-",
        },
        {
          id: "missingAlarms",
          header: "missingAlarms",
          cell: item =>  item.missingAlarms.toString() || "None",
        }
      ];
