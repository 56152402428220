import { useEffect, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import { ContentOrigin } from '../../interfaces';
import FormContent from './FormContent';
import ToolsPanel from './ToolsPanel';
import { appLayoutLabels } from '../../common/labels';

export default function onboardingForm() {
  const [contentOrigins, setContentOrigins] = useState<ContentOrigin[]>([]);
  const [toolsOpen, setToolsOpen] = useState(true);
  return (
    <AppLayout
      navigation={<ServiceNavigation />} 
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Onboarding page', href: '' }
          ]}
        />
      }
      content={
        <FormContent
          contentOrigins={contentOrigins}
          // Changes the Help panel content when the user clicks an 'info' link
          onTopicSelect={topic => {
            setToolsOpen(true);
          }}
        />
      }
      contentType="form"
      tools={<ToolsPanel />}
      toolsOpen={toolsOpen}
      onToolsChange={event => setToolsOpen(event.detail.open)}
      ariaLabels={appLayoutLabels}
    />
  );
}
