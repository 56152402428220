import { useState,useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import Container from '@amzn/awsui-components-react/polaris/container';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Header from '@amzn/awsui-components-react/polaris/header';
import Form from '@amzn/awsui-components-react/polaris/form';
import Button from '@amzn/awsui-components-react/polaris/button';
import Link from '@amzn/awsui-components-react/polaris/link';
import Box from "@amzn/awsui-components-react/polaris/box";
import Select, {SelectProps} from '@amzn/awsui-components-react/polaris/select';
import Multiselect, {MultiselectProps} from '@amzn/awsui-components-react/polaris/multiselect';
import Input from '@amzn/awsui-components-react/polaris/input';
import Modal from "@amzn/awsui-components-react/polaris/modal";
import AttributeEditor from '@amzn/awsui-components-react/polaris/attribute-editor';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { ContentOrigin } from '../../interfaces';
import { useSelect, useMultiselect, useInput } from '../hooks';
import ApiFactory from '../../api/ApiFactory';
import { AccountDict } from '../../api/generated-src'
interface SingleSelectDropdownOptions {
  label: string;
  value: string;
  disabled: boolean;
}
interface DisplayStackProps {
  contentOrigins: ContentOrigin[];
  onTopicSelect: (topic: string) => void;
}
interface SelectStackOptions{
  accountName: string;
  stackList: SingleSelectDropdownOptions[]; 
  react_use_state: any;
}
interface CustomizedState {
  // this interface is defined to receive data from the onboarding page
  // which includes the response returned from backend lambda
  // and serviceName, iamRoleList provided by users on the onboarding page 
  data : object,
  service: string,
  iamRoleList: string[],
}

export default function DisplayStack({ contentOrigins, onTopicSelect }: DisplayStackProps) {
/* a page for displaying available stacks in each of the onboarding account
 * 
 */
  const Api = ApiFactory();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const state = location.state as CustomizedState; // Type Casting, then you can get the params passed via router
  // accountDict is a nested dictionary that maps serviceName with accountArn with selected stackList
  let accountDict : AccountDict = {serviceName: "" , accountStackMap: {}};
  // stackOptions is used for multiselect
  let stackOptions : SelectStackOptions[] = [];
  /* accountIamMap is used to convert account name back to iam role arn. we display user's account name as the header
  in multi select */
  let accountIamMap: { [key: string]: string } = {};
  //accountStackMap maps accountArn with a list of selected stacks by the user
  var accountStackMap :{ [key:string] : string[]} = {};
  var i = 0;
  if (typeof(state) !== 'undefined' && state != null) {
  //process data received from the onboarding form
    for (let key in state.data) {
      let value = state.data[key];
      // create stack option object that matches the multi select option interface
      const stackOption : SelectStackOptions = {
	accountName: key,
	stackList: listToOptions(value),
	react_use_state: useState<MultiselectProps.Options>([])
      };
      stackOptions.push(stackOption);
     // map account name with account arn
     // used this var to convert data header (account name) back to account arn 
      accountIamMap[key] = (state.iamRoleList[i]);
      i++;
     }
 } 
  for (var stackops of stackOptions){
  // loop through the selected options object for each account 
    var accountName : string = stackops.accountName;
  // use accountIamMap to convert accountName back to iam role arn for backend lambda
    var accountArn : string = accountIamMap[accountName];
    for (var stackName of stackops.react_use_state[0]){
      // for each account's stackops, loop through the react_use_state, which represents the stacks selected in each multi select section
      // then create entry in accountStackMap or push the stackName to the accountStackMap
      if (accountStackMap.hasOwnProperty(accountArn) == false) accountStackMap[accountArn] = [stackName.value];
      else accountStackMap[accountArn].push(stackName.value);
    }
  }
  function listToOptions(stacks:{[key:string] : boolean}) : SingleSelectDropdownOptions[] {
  // convert stackLists to dropdown option type for selection
  // :param stacks: a list of stack names with status
  // status = true means it has been onboarded and we disabled that option
    let result : SingleSelectDropdownOptions[] = [];
    Object.keys(stacks).forEach(stackName => {
      let value = stacks[stackName];
      const option : SingleSelectDropdownOptions = {label: stackName, value: stackName, disabled: value};
      result.push(option);
    });
    return result;   	
} 

  async function postRecord(){ 
  // post record to the ddb 
  // init object whose format aligns with the backend lambda
    accountDict = {serviceName: state.service, accountStackMap: accountStackMap};
    Api.postStacks(accountDict);
      setIsLoading(true);
    setTimeout(function(){ 
    //add a 5s wait before redirected user to the alarm page 
    // buy time for posting records in ddb
      window.location.href = '#/stackTable';
    }, 8000);
  }
  return (
    <Form
      header={<Header variant="h1">Welcome to AWSVision Onboarding Table</Header>}
      actions={
        // located at the bottom of the form
        <SpaceBetween direction="horizontal" size="xs">
          <Button href="#/onboardingPage" variant="link">Previous</Button>
          <Button  variant="primary" onClick={() => { setVisible(true);}}>
           Confirm
	</Button>
        </SpaceBetween>
      }
    >
    <Container header={<Header>Available Stacks</Header>}>
      <SpaceBetween size="l">
	{stackOptions.map((account) =>( 
		[<Header key={account.accountName}> {account.accountName} </Header>,
 		<Multiselect
		  id = {account.accountName}
                  selectedOptions={account.react_use_state[0]}
                  onChange={ ({detail})  => account.react_use_state[1](detail.selectedOptions) }
                  deselectAriaLabel={(e) => 'Remove ' + e.value}
                  options={account.stackList}
                  placeholder="Select Stacks"
                  selectedAriaLabel="Selected"
                />,
		<SpaceBetween direction="horizontal" size="xs">
		<Button variant="primary"onClick= { () => {
			var result : SingleSelectDropdownOptions[] = [];
			for (var op of account.stackList){
				if (op.disabled == false) { result.push(op); }
			}
			account.react_use_state[1](result)
		}}> Select All </Button> 
		<Button variant="link"onClick= { () => {account.react_use_state[1]([])}}> Clear All </Button>
	        </SpaceBetween>
		]
	))} 
      </SpaceBetween>
    </Container>
        <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        closeAriaLabel="Close modal"
        footer={
                <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
                <Button  variant="primary" loading={isLoading} onClick={() => {postRecord()}}>Submit</Button>
                </SpaceBetween>
                </Box>
                }
      		header="Please confirm your selection"
    		>
    <SpaceBetween size="l">
	{Object.keys(accountStackMap).map((account) => {
	 return (
		[<Header key={account}> {account} </Header>,
		 <h3> {accountStackMap[account].toString()} </h3>
		])
	})}
     </SpaceBetween>
    </Modal>
  </Form>
  );
}
