import { useState } from 'react';
import Form from '@amzn/awsui-components-react/polaris/form';
import { useHistory,useLocation } from 'react-router-dom';
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Container from '@amzn/awsui-components-react/polaris/container';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import Select from '@amzn/awsui-components-react/polaris/select';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import Input from '@amzn/awsui-components-react/polaris/input';
import AttributeEditor from '@amzn/awsui-components-react/polaris/attribute-editor';
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { ContentOrigin } from '../../interfaces';
import Box from "@amzn/awsui-components-react/polaris/box";
import { useSelect, useMultiselect, useInput } from '../hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import ApiFactory from '../../api/ApiFactory';
import { Account } from '../../api/generated-src';
interface HeaderItem {
//this is used for updating the attribute editor for iam role list
  name: string;
  value: string;
}

interface OnboardingTableProps{
  contentOrigins: ContentOrigin[];
  onTopicSelect: (topic: string) => void;
}
interface CustomizedState {
  // this interface is defined to receive service name from the service table 
  data : string,
}
function updateHeader(items: Array<HeaderItem>, itemIndex: number, updater: (header: HeaderItem) => HeaderItem) {
// param: items: list of iam roles
// param: itemindex: index of the item
// param: updater: update the item 
// return: a new item or new value in the input field
  return items.map((item, index) => (index === itemIndex ? updater(item) : item));
}
export default function OnboardingTable({ contentOrigins, onTopicSelect}: OnboardingTableProps) {
  // implementation of the onboarding table
  const contentOrigin = useSelect();
  const location = useLocation();
  const history = useHistory();
  const state = location.state as CustomizedState; // Type Casting, then you can get the params passed via router
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  var serviceName = useInput();
  if (typeof(state)!== undefined && state != null){ serviceName.value = state.data;}
  const Api = ApiFactory();
  const [headers, setHeaders] = useState<Array<HeaderItem>>([{ name: '', value: '' }]);
  async function displayStacks(){
  // async function to process data received from displayStacks lambda function
    var iamRoleList:string[];
    setIsLoading(true);
    iamRoleList = [];
    for (var header of headers){iamRoleList.push(header.value);}
    await Api.displayStacks(iamRoleList,serviceName.value).then(response => {
	  history.push({
	    pathname: '/displayStacks',
	    state: {data: response.data, service: serviceName.value, iamRoleList: iamRoleList}
	    })
    	  })
	.catch( error => {
	    if (error.response) {
	      // Request made and server responded
	      console.log(error.response.data);
	      console.log(error.response.status);
	      console.log(error.response.headers);
	    } else if (error.request) {
	      // The request was made but no response was received
	      console.log(error.request);
	    } else {
	      // Something happened in setting up the request that triggered an Error
	      console.log('Error', error.message);
	    }
    	    setIsLoading(false);
	    setVisible(true);
	  });
  }

  return (
    <Form
      header={<Header variant="h1">Welcome to AWSVision Onboarding Table</Header>}
      actions={
        // located at the bottom of the form
        <SpaceBetween direction="horizontal" size="xs">
         <Button href="/"variant="link">Cancel</Button>
          <Button variant="primary" loading={isLoading} onClick={() => displayStacks()}>
            Verify
          </Button>
        </SpaceBetween>
      }
    >
    <Container header={<Header>Onboarding Table</Header>}>
      <SpaceBetween size="l">
        <FormField
          label="Service Name"
          description="The service name that the following accounts belong to."
        >
        <Input {...serviceName} />
        </FormField>
        <AttributeEditor<HeaderItem>
          addButtonText="Add IAM ARN"
          removeButtonText="Remove IAM ARN"
          items={headers}
          definition={[
            {
              label: 'IAM role ARN assumed by Vision',
              info: (
                <Link href="#/" variant="info" onFollow={() => onTopicSelect('customHeaders')}>
                  Info
                </Link>
              ),
              control: (item, index) => (
                <Input
                  value={item.name}
                  placeholder="IAM role ARN"
                  onChange={event =>
                    setHeaders(headers =>
                      updateHeader(headers, index, header => ({ ...header, name: event.detail.value, value: event.detail.value }))
                    )
                  }
                />
              )
            },
          ]}
          onAddButtonClick={() => setHeaders(headers => headers.concat({ name: '', value: '' }))}
          onRemoveButtonClick={event =>
            setHeaders(headers => headers.filter((item, index) => index !== event.detail.itemIndex))
          }
        />
      </SpaceBetween>
    </Container>
        <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        closeAriaLabel="Close modal"
        footer={
                <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary" onClick={() => setVisible(false)}>Cancel</Button>
                </SpaceBetween>
                </Box>
                }
                header="Verification Failed"
                >
    <SpaceBetween size="l">
 	Please check your input
     </SpaceBetween>
    </Modal>
   </Form>
  );
}
