import { ReactNode } from 'react';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { AccountMissingAlarm} from '../../api/generated-src';
import Box from '@amzn/awsui-components-react/polaris/box';
import ApiFactory from '../../api/ApiFactory';
import Link from '@amzn/awsui-components-react/polaris/link';
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";


export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<AccountMissingAlarm>[] = [
// Table definition
        {
          id: "resourceType",
          header: "resourceType",
          cell: item => item.resourceType|| "-",
        },
        {
          id: "missingAlarmType",
          header: "missingAlarmType",
          cell: item => item.missingAlarmType|| "-",
        },
        {
          id: "numberOfAlarmMissing",
          header: "numberOfAlarmMissing",
          cell: item => item.numberOfAlarmMissing|| 0,
        },
        {
          id: "stackList",
          header: "stackList",
          cell: (item) => (
		<ExpandableSection header="stacks missing this alarm">
		 {item.stackList.map((key,index) => {
		return <Link href="#/stackTable"> {item.stackList[index]} </Link>
	})}</ExpandableSection> ) 
        },
      ];
