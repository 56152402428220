import { ReactNode } from 'react';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { ServiceMissingAlarm} from '../../api/generated-src';
import ApiFactory from '../../api/ApiFactory';
import Link from '@amzn/awsui-components-react/polaris/link';
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<ServiceMissingAlarm>[] = [
// Table definition
        {
          id: "resourceType",
          header: "resourceType",
          cell: item => item.resourceType|| "-",
        },
        {
          id: "missingAlarmType",
          header: "missingAlarmType",
          cell: item => item.missingAlarmType|| "-",
        },
        {
          id: "numberOfAlarmMissing",
          header: "numberOfAlarmMissing",
          cell: item => item.numberOfAlarmMissing|| 0,
        },
        { 
          id: "accountList",
          header: "accountList",
          cell: (item) => ( <ExpandableSection>
		{item.accountList.map((key,index) => {
                return <Link href="#/accountTable"> {item.accountList[index]} </Link>
        })}</ExpandableSection>
	) 
        },
      ];
