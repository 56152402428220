import React, { useEffect, useRef, useState } from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import { TableProps }from '@amzn/awsui-components-react/polaris/table';
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Table from '@amzn/awsui-components-react/polaris/table';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import { COLUMN_DEFINITIONS } from './table-config';
import { AccountMissingAlarm } from '../../api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import ApiFactory from '../../api/ApiFactory';

export default function accountMissingAlarmTable() {
// returns the account missing alarm table grouped by account Id and account name
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<object>();
  const [fetchError, setFetchError] = useState<string | null>(null);
  const Api = ApiFactory();
  const loadItems = async () => {
    setLoading(true);
    setFetchError(null);
    try {
	setResponse( (await Api.getAccountMissingAlarms()).data);
    } catch (error: unknown) {
      setFetchError(error instanceof Error ? error.message : String(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadItems();
  }, []);
  // a dictionary that maps accountName + accountId with missing alarm info
  var accountMissingAlarmDict:  {[key:string]:AccountMissingAlarm[]} = {"":[]}; 

  // process data received from the lambda
  // sample response {"account_info": { "missing_alarm_info" : [list of stacks]}}
  if (response != undefined){
   Object.keys(response).forEach(account => {
	// get acccount info from response's key
	var account_info : string[] = account.split(":");
	var accountName = account_info[0];
	var accountId = account_info[1];
	Object.keys(response[account]).forEach(resourceAlarmType => {
	   // get missing alarm info for the second layer dictionary
	   var missingAlarmInfo : string[] = resourceAlarmType.split("&");
	   var resourceType = missingAlarmInfo[0];
	   var missingAlarmType = missingAlarmInfo[1];
	   var stackList : string[] = response[account][resourceAlarmType];
	   var numberOfAlarmMissing = response[account][resourceAlarmType].length;
	   let accountObj : AccountMissingAlarm = {
		resourceType: resourceType,
		missingAlarmType: missingAlarmType,
		stackList: stackList,
		numberOfAlarmMissing: numberOfAlarmMissing,
	}
      // create entry in the dictionary if account does not exist
      if (accountMissingAlarmDict.hasOwnProperty(account) == false){
        accountMissingAlarmDict[account] = [accountObj];
      } 
      else{
        accountMissingAlarmDict[account].push(accountObj);
      } 
      // remove empty string at the end. empty string is needed to display header when loading response
      delete accountMissingAlarmDict[""];
      });
    });
    }
    //customizes visible columns in the table
    const visible: string[] =  ["resourceType","missingAlarmType","numberOfAlarmMissing","stackList"];
   return (
   <Container>
      <SpaceBetween size="l">
	{Object.keys(accountMissingAlarmDict).map((key)=> {
	return(
	// process info and sort by resource type
	    [<Header key={key}> {key} </Header>,
	     <Table
	      loading={loading}
      	      loadingText="Loading instances"
	      // empty state when there is no resources to display, a button to let user to onboard stacks
	      empty={
		<Box textAlign="center" color="inherit">
		  <b>No resources</b>
		  <Box
		    padding={{ bottom: "s" }}
		    variant="p"
		    color="inherit"
		  >
		    No resources to display.
		  </Box>
		  <Button href="#/onboardingPage" variant="primary">Onboard your stacks</Button>
		</Box>
	      }
	      columnDefinitions={COLUMN_DEFINITIONS}
	      // sort items in the table first based on their resource type then sort based on number of alarm missing in descending order
      	      items={accountMissingAlarmDict[key].sort((a,b) => (a.resourceType > b.resourceType ? -1 : 1 && a.numberOfAlarmMissing > b.numberOfAlarmMissing ? -1 : 1))} 
	      visibleColumns={visible}
         />])
	})}	
     </SpaceBetween>
  </Container>
  )
}
