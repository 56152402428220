import React, { useEffect, useRef, useState } from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import Header from '@amzn/awsui-components-react/polaris/header';
import Table from '@amzn/awsui-components-react/polaris/table';
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from '@amzn/awsui-components-react/polaris/button';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import { COLUMN_DEFINITIONS } from './table-config';
import { StackMissingAlarm } from '../../api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import ApiFactory from '../../api/ApiFactory';

export default function stackMissingAlSubmitarmTable() {
  // displays the missing alarm info on a stack level
  // header of the table is grouped by stackID
  const [loading, setLoading] = useState<boolean>(false);
  const [allItems, setAllItems] = useState< {[key:string]:StackMissingAlarm[]}>({"":[]});
  const [fetchError, setFetchError] = useState<string | null>(null);
  const Api = ApiFactory();
  const loadItems = async () => {
    setLoading(true);
    setFetchError(null);
    try {
	setAllItems( (await Api.getStackMissingAlarms()).data || {"":[]});
    } catch (error: unknown) {
      setFetchError(error instanceof Error ? error.message : String(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadItems();
  }, []);
  //visible columns;
  const visible: string[] = ["resourceType","resourceName","accountId","serviceName","missingAlarms"]; 
  return (
   <Container>
      <SpaceBetween size="l">
	{Object.keys(allItems).sort().map((key)=> {
	// map stackId with a list of StackMissingAlarm options
	// items are sorted by resource type
	return(
	    [<Header key={key}> {key} </Header>,
	     <Table
	      loading={loading}
      	      loadingText="Loading instances"
              empty={
                <Box textAlign="center" color="inherit">
                  <b>No resources</b>
                  <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                  >
                    No resources to display.
                  </Box>
                  <Button href="#/onboardingPage" variant="primary">Onboard your stacks</Button>
                </Box>
              }
      	      columnDefinitions={COLUMN_DEFINITIONS}
	      //sort items based on resource type name
      	      items={allItems[key].sort((a,b) => (a.resourceType > b.resourceType ? 1 : -1))}
	      visibleColumns={visible}
         />])
	})}	
     </SpaceBetween>
  </Container>
  )
}
