import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { ContentOrigin } from '../../interfaces';
import DisplayStack from './DisplayStack';  

interface FormContentProps {
  contentOrigins: ContentOrigin[];
  onTopicSelect: (topic: string) => void;
}

export default function FormContent({ contentOrigins, onTopicSelect }: FormContentProps) {
  return (

      <SpaceBetween size="l">
        <DisplayStack contentOrigins={contentOrigins} onTopicSelect={onTopicSelect} />
      </SpaceBetween>
  );
}
