import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import { Icon } from '@amzn/awsui-components-react';


export default function ToolsPanel() {
  return (
    <HelpPanel
      header={<h2>AWSVision</h2>}
      footer={
      <ul> 
	<li>
            <span>
                <a href="/"target="_blank" rel="noopener noreferrer">
                    <Icon name="contact"/>
                    &nbsp;&nbsp;Vision's Wiki
                </a>
            </span>
        </li>
        <li>
            <span>
                <a href="https://issues.amazon.com/issues/create?template=2d244935-648a-47e6-9f5a-b9fd9b1299a1" target="_blank" rel="noopener noreferrer">
                    <Icon name="contact"/>
                    &nbsp;&nbsp;Feature Request
                </a>
            </span>
        </li>
        <li>
            <span>
                <a href="https://issues.amazon.com/issues/create?template=caf78af4-fcb8-4690-b973-f07c406b54f8" target="_blank" rel="noopener noreferrer">
                    <Icon name="bug"/>
                    &nbsp;&nbsp;Report a Bug
                </a>
            </span>
        </li>
               <li>
            <span>
                <a href="https://w.amazon.com/bin/view/AWSCP/MVPDevOps/Projects/Prometheus/OnePager" target="_blank" rel="noopener noreferrer">
                    <Icon name="external"/>
                    &nbsp;&nbsp;FAQs
                </a>
            </span>
        </li>
    </ul>
      }
    >
   <div> 
	<h3>Note for Stack Selection</h3>
        <ul>
          <li> will be addede soon </li>
        </ul>	
   </div>

   </HelpPanel>
  );
}

