import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import { Icon } from '@amzn/awsui-components-react';


export default function ToolsPanel() {
  return (
    <HelpPanel
      header={<h2>AWSVision</h2>}
      footer={
      <ul>                   
        <li>
            <span>
                <a href="https://w.amazon.com/bin/view/Users/imsid/InternProject/Vision/"target="_blank" rel="noopener noreferrer">
                    <Icon name="contact"/>
                    &nbsp;&nbsp;Vision's Wiki
                </a>
            </span>
        </li>
        <li>
            <span>
                <a href="https://issues.amazon.com/issues/create?assignedFolder=4a0b0297-32cf-4a2d-82a2-368ac3ba6185" target="_blank" rel="noopener noreferrer">
                    <Icon name="contact"/>
                    &nbsp;&nbsp;Feature Request
                </a>
            </span>
        </li>
        <li>
            <span>
                <a href="https://sim.amazon.com/issues/create?template=68a3b68b-6551-4298-a479-39836b2cccfe" target="_blank" rel="noopener noreferrer">
                    <Icon name="bug"/>
                    &nbsp;&nbsp;Report a Bug
                </a>
            </span>
        </li>
               <li>
            <span>
                <a href="https://w.amazon.com/bin/view/AWSCP/MVPDevOps/Projects/Prometheus/OnePager" target="_blank" rel="noopener noreferrer">
                    <Icon name="external"/>
                    &nbsp;&nbsp;FAQs
                </a>
            </span>
        </li>
    </ul> 
      }
    />
  );
}
