import { useState } from 'react';
import { CheckboxProps } from '@amzn/awsui-components-react/polaris/checkbox';
import { InputProps } from '@amzn/awsui-components-react/polaris/input';
import { MultiselectProps } from '@amzn/awsui-components-react/polaris/multiselect';
import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';

export function useCheckbox(defaultValue: boolean = false): Pick<CheckboxProps, 'checked' | 'onChange'> {
  const [checked, setChecked] = useState(defaultValue);
  return { checked, onChange: event => setChecked(event.detail.checked) };
}

export function useRadio(defaultValue: string): Pick<RadioGroupProps, 'value' | 'onChange'> {
  const [value, setValue] = useState(defaultValue);
  return { value, onChange: event => setValue(event.detail.value) };
}

export function useInput(defaultValue: string = ''): Pick<InputProps, 'value' | 'onChange'> {
  const [value, setValue] = useState(defaultValue);
  return { value, onChange: event => setValue(event.detail.value) };
}

export function useSelect(
  defaultOption: SelectProps.Option | null = null
): Pick<SelectProps, 'selectedOption' | 'onChange'> {
  const [selectedOption, setSelected] = useState(defaultOption);
  return { selectedOption, onChange: event => setSelected(event.detail.selectedOption) };
}

export function useMultiselect(
  defaultSelection: MultiselectProps.Options = []
): Pick<MultiselectProps, 'selectedOptions' | 'onChange'> {
  const [selectedOptions, setSelected] = useState(defaultSelection);
  return { selectedOptions, onChange: event => setSelected(event.detail.selectedOptions) };
}
